import React from 'react';
import { Box, BoxProps } from '@mui/material';
import AppBarOffsetedScrollContainer from '../AppBarOffsetedScrollContainer';
import PageSectionContent, { PageSectionContentProps } from './PageSectionContent';

interface PageSectionProps {
  id?: string;
  bgcolor?: BoxProps['bgcolor'];
  color?: BoxProps['color'];
  sx?: BoxProps['sx'];
  maxWidth?: PageSectionContentProps['maxWidth'];
  py?: PageSectionContentProps['py'];
}

export default (props: React.PropsWithChildren<PageSectionProps>) => (
  <Box component='section' id={props.id} bgcolor={props.bgcolor} color={props.color}>
    {/* TODO: Wprowadzić w PageSectionWithSideImage, to jest w innym PR. */}
    <AppBarOffsetedScrollContainer maxWidth={props.maxWidth} id={props.id}>
      <PageSectionContent maxWidth={props.maxWidth} py={props.py}>
        {props.children}
      </PageSectionContent>
    </AppBarOffsetedScrollContainer>
  </Box>
);
