import React from 'react';
import { Container, ContainerProps } from '@mui/material';
import { height } from './ResponsiveAppBar';
import theme from '../../theme';

export default (props: React.PropsWithChildren<{ id?: string; maxWidth: ContainerProps['maxWidth'] }>) => (
  <Container
    id={props.id}
    maxWidth={props.maxWidth}
    sx={{
      // https://stackoverflow.com/questions/4086107/fixed-page-header-overlaps-in-page-anchors
      scrollMarginTop: `calc(${height} + ${theme.spacing(3)})`
    }}
  >
    {props.children}
  </Container>
);
