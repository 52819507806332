import React from 'react';
import type * as CSS from 'csstype';
import { Box, Stack, Typography, TypographyProps } from '@mui/material';
import { Button } from 'gatsby-material-ui-components';

export default (props: {
  color: TypographyProps['color'];
  slug: string;
  date: string;
  title: string;
  titleVariant?: TypographyProps['variant'];
  titleComponent: React.ElementType;
  excerpt: string;
  maxWidth?: CSS.Property.MaxWidth;
}) => (
  <Stack maxWidth={props.maxWidth}>
    <Typography variant='caption' textTransform='uppercase' color={props.color} gutterBottom>
      {props.date}
    </Typography>
    <Typography
      variant={props.titleVariant}
      component={props.titleComponent}
      textTransform='uppercase'
      color={props.color}
      gutterBottom
    >
      {props.title}
    </Typography>
    <Typography variant='body2' color={props.color} gutterBottom>
      {props.excerpt}
    </Typography>
    <Box display='flex' justifyContent='center'>
      <Button fullWidth to={props.slug} variant='contained' sx={{ marginTop: 2, maxWidth: '500px' }}>
        Więcej
      </Button>
    </Box>
  </Stack>
);
