import React from 'react';
import { Container, Box, ContainerProps, BoxProps } from '@mui/material';

export interface PageSectionContentProps {
  maxWidth?: ContainerProps['maxWidth'];
  py?: BoxProps['py'];
}

export default (props: React.PropsWithChildren<PageSectionContentProps>) => (
  <Container maxWidth={props.maxWidth}>
    <Box
      py={props.py ?? { xs: 3, sm: 6 }}
      sx={{
        // Można zawęzić to faktycznie wykorzystywanych elementów h1 itd.
        '> h1, > h2, > h3, > caption': {
          textShadow: '1px 2px 2px rgb(31 52 77 / 20%)'
        }
      }}
    >
      {props.children}
    </Box>
  </Container>
);
