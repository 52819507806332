import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Grid, Stack, Divider, useTheme, useMediaQuery } from '@mui/material';
import StandardFrame from '../components/StandardFrame';
import PostCard from '../components/Cards/PostCard';
import FeaturedPostCard from '../components/Cards/FeaturedPostCard';
import HeroTitle from '../components/Heros/HeroTitle';
import StandardBreadcrumbs from '../components/StandardBreadcrumbs';
import Hero from '../components/Heros/Hero';
import PageSection from '../components/Sections/PageSection';
import PageSectionTitle from '../components/Sections/PageSectionTitle';

export const pageQuery = graphql`
  query PostIndex {
    featuredLong: allMarkdownRemark(
      filter: { childPost: { id: { ne: null } }, frontmatter: { featured: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___featured] }
      limit: 1
    ) {
      edges {
        node {
          ...PostCardData
          excerptLong: excerpt(pruneLength: 1000)
        }
      }
    }
    featuredShort: allMarkdownRemark(
      filter: { childPost: { id: { ne: null } }, frontmatter: { featured: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___featured] }
      limit: 3
    ) {
      edges {
        node {
          ...PostCardData
          excerptShort: excerpt(pruneLength: 400)
        }
      }
    }
    all: allMarkdownRemark(
      filter: { childPost: { id: { ne: null } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          ...PostCardData
        }
      }
    }
  }
`;

export default ({ data: { featuredLong, featuredShort, all } }: PageProps<Queries.PostIndexQuery>) => {
  const theme = useTheme();
  return (
    <>
      <Helmet title='Blog' />
      <StandardFrame>
        <Hero
          image={
            <StaticImage
              style={{ height: '100%' }}
              layout='fullWidth'
              quality={95}
              src='../images/hero_photo11.jpg'
              alt=''
            />
          }
        >
          <HeroTitle>Blog</HeroTitle>
        </Hero>
        <PageSection maxWidth='lg' bgcolor='tertiary.main'>
          <PageSectionTitle>Polecane artykuły</PageSectionTitle>
          <Grid container spacing={{ xs: 2, sm: 4 }}>
            <Grid item xs={12} md={6} alignSelf='center'>
              {featuredLong.edges.slice(0, 1).map(({ node }) => (
                <FeaturedPostCard
                  key={node.id}
                  color='tertiary.contrastText'
                  slug={node.fields!.slug}
                  title={node.frontmatter!.title!}
                  date={node.frontmatter!.date!}
                  titleVariant='h3'
                  titleComponent='h2'
                  excerpt={node.excerptLong!}
                />
              ))}
            </Grid>
            <Grid item xs={12} md='auto'>
              <Divider
                sx={{ borderColor: 'tertiary.contrastText' }}
                // https://github.com/mui/material-ui/issues/29908
                orientation={useMediaQuery(theme.breakpoints.down('md')) ? 'horizontal' : 'vertical'}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack
                direction='column'
                spacing={{ xs: 2, sm: 4 }}
                divider={<Divider flexItem sx={{ borderColor: 'tertiary.contrastText' }} orientation='horizontal' />}
              >
                {featuredShort.edges.slice(1).map(({ node }) => (
                  <FeaturedPostCard
                    key={node.id}
                    color='tertiary.contrastText'
                    slug={node.fields!.slug}
                    date={node.frontmatter!.date!}
                    title={node.frontmatter!.title!}
                    titleVariant='h4'
                    titleComponent='h2'
                    excerpt={node.excerptShort!}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </PageSection>
        <PageSection maxWidth='lg'>
          <StandardBreadcrumbs mt={3} path={[{ title: 'Blog', link: '/blog' }]} />
          <Grid container spacing={3} mt={0} mb={6}>
            {all.edges.map(({ node }) => (
              <Grid key={node.id} item xs={12} sm={6}>
                <PostCard
                  slug={node.fields!.slug}
                  date={node.frontmatter!.date!}
                  title={node.frontmatter!.title!}
                  excerpt={node.excerpt!}
                  image={node.frontmatter!.card_image!.childImageSharp!.gatsbyImageData!}
                />
              </Grid>
            ))}
          </Grid>
        </PageSection>
      </StandardFrame>
    </>
  );
};
